<template>
  <el-select
    filterable
    v-model="id"
    placeholder="请选择客户"
    clearable
    size="small"
    :disabled="showType ===  DETAILS"
  > 
    <el-option
      v-for="(item, index) in this.list"
      :key="index"
      :label="item.customerName + '（' + item.customerNum + '）'"
      :value="item.id"
    ></el-option>
  </el-select>
</template>
<script>
import { DETAILS } from '../utils'

export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    userId: {
      type: Number,
      default: 0,
    },
    types: {
      type: String,
      default: '',
    },
    showType:{
      type: String,
      default: '',
    }
  },
  created() {
  },
  data() {
    return {
      DETAILS,
    };
  },
  computed: {
    id: {
      get() {
        if (!this.userId) {
          return "";
        }
        return this.userId;
      },
      set(val) {
        this.$emit("update:userId", val * 1);
        this.$emit("success", val * 1);
      },
    },
  },
};
</script>
