export function getKeyValue(obj) {
  return obj.reduce((acc, cur) => {
    acc[cur.value] = cur.label
    return acc
  }, {})
}

export function downloadByUrl(url, filename = '默认文件名') {
  const link = document.createElement('a')
  link.download = decodeURIComponent(filename)
  link.style.display = 'none'
  link.href = url
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}

export const DETAILS = 'details'