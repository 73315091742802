<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    title="计提科目设置"
    width="60%"
    
    destroy-on-close
  >

    <qzf-button jz="false" button_code="jtsj_yjsz" @success="initTax()" size="small"  type="primary" style="position:relative;top:2px;float: right; margin-bottom: 10px;">
      <el-icon><Setting /></el-icon><span  > 一键设置</span>
    </qzf-button>
    <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
      

      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" prop="id" align="center" width="50" type="index"></el-table-column>
      <el-table-column label="税金计提项目 " align="center" min-width="180">
        <template #default="{row}">
          <span>{{row.collectionProject}}</span>
        </template>
      </el-table-column>
      <el-table-column label="科目设置状态" align="center" min-width="180">
        <template #default="{row}">
          <span style="color:red" v-if="!row.borrowSubjectId || !row.loanSubjectId">未设置</span>
          <span style="color:green" v-else>已设置</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width='180' class-name="top_right_btns">
        <template #default="{row}">
          <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('jtsj_jtsj')" effect="dark">
            <i class="iconfont icon-bianji" @click="handleCreate(row)"></i>
          </el-tooltip>
          <!-- <el-tooltip v-if="row.collectionProject != '企业所得税' && $buttonStatus('jtsj_jtsj')" content="编辑申报周期" placement="top">
            <i class="el-icon-setting" @click="showTankuang(row)"></i>
          </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="small">确定</el-button
        >
      </span>
    </template> -->
  </el-dialog>

  <!-- 弹框模板 -->
  <el-dialog :close-on-click-modal="false"
    draggable
    v-model="dialogFormVisible"
    title="计提科目模板"
    width="30%"
    
    :modal="false"
    destroy-on-close
  >
    <el-form ref="dataForm" :model="temp" label-position="right" label-width="80px">
        <div class="common_dialog_box2 clearfix">
          <el-form-item label="模板名称:" prop="type" class="item">
            <span v-if="temp.collectionProject == '增值税' && comType == '1'">减免{{temp.collectionProject}}</span>
            <span v-else-if="temp.collectionProject == '残疾人就业保障金'">缴纳{{temp.collectionProject}}</span>
            <span v-else-if="temp.collectionProject == '个人所得税'">缴纳{{temp.collectionProject}}</span>
            <span v-else>计提{{temp.collectionProject}}</span>
          </el-form-item>
        </div>
        <table class="dialog_table" cellspacing="0" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
          <tr style="width:40%;background: var(--themeColor,#17a2b8);color: #fff;font-size: 14px;font-weight: 600;">
            <td>摘要</td>
            <td>分录方向</td>
            <td>会计科目</td>
          </tr>
          <tr style="width:20%;">
            <td v-if="temp.collectionProject == '增值税' && comType == '1'">减免{{temp.collectionProject}}</td>
            <td v-else-if="temp.collectionProject == '残疾人就业保障金'">缴纳{{temp.collectionProject}}</td>
            <td v-else-if="temp.collectionProject == '个人所得税'">缴纳{{temp.collectionProject}}</td>
            <td v-else>计提{{temp.collectionProject}}</td>
            <td>借方</td>
            <td>
              <!-- <subject-list :subjectCode.sync="temp.borrow"></subject-list> -->
              <subject-list v-model:subjectId="temp.borrowSubjectId" v-model:fzhsItemId="temp.borrowFzhsId"></subject-list>
            </td>
          </tr>
          <tr style="width:40%;border-bottom:none;">
            <td v-if="temp.collectionProject == '增值税' && comType == '1'">减免{{temp.collectionProject}}</td>
            <td v-else-if="temp.collectionProject == '残疾人就业保障金'">缴纳{{temp.collectionProject}}</td>
            <td v-else-if="temp.collectionProject == '个人所得税'">缴纳{{temp.collectionProject}}</td>
            <td v-else>计提{{temp.collectionProject}}</td>
            <td>贷方</td>
            <td>
              <subject-list v-model:subjectId="temp.loanSubjectId" v-model:fzhsItemId="temp.loanFzhsId"></subject-list>
            </td>
          </tr>
        </table>
        <div class="bottom_font" v-if="temp.collectionProject == '增值税' && comType == '2' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：应交税费-应交增值税-转出未交增值税</p>
          <p style="text-indent: 25px;">贷：应交税费-未交增值税</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '增值税' && comType == '1' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：应交税费-应交增值税(小规模)</p>
          <p style="text-indent: 25px;">贷：营业外收入-政府补助</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '印花税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-印花税</p>
          <p style="text-indent: 25px;">贷：应交税费-应交印花税</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '城市维护建设税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-城市维护建设税</p>
          <p style="text-indent: 25px;">贷：应交税费-应交城市维护建设税</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '房产税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-房产税</p>
          <p style="text-indent: 25px;">贷：应交税费-房产税</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '城镇土地使用税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-城镇土地使用税</p>
          <p style="text-indent: 25px;">贷：应交税费-城镇土地使用税</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '教育费附加' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-教育费附加</p>
          <p style="text-indent: 25px;">贷：应交税费-应交教育费附加</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '地方教育附加' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-地方教育附加</p>
          <p style="text-indent: 25px;">贷：应交税费-应交地方教育附加</p>
        </div>
        <div class="bottom_font" v-if="temp.collectionProject == '水利建设专项收入' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加-地方水利基金</p>
          <p style="text-indent: 25px;">贷：应交税费-应交地方水利基金</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '个人所得税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：应交税费-应交个人所得税</p>
          <p style="text-indent: 25px;">贷：银行存款_XXX</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '企业所得税' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：所得税费用</p>
          <p style="text-indent: 25px;">贷：应交税费-应交企业所得税</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '残疾人就业保障金' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：管理费用-残保金</p>
          <p style="text-indent: 25px;">贷：银行存款_XXX</p>
        </div>

        <div class="bottom_font" v-if="temp.collectionProject == '文化事业建设费' && type != '民办非'">
          <p>【示例】（仅做参考）</p>
          <p style="text-indent: 10px;">借：税金及附加_文化事业建设费</p>
          <p style="text-indent: 25px;">贷：应交税费_文化事业建设费</p>
        </div>
      </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateData()" size="small">确定</el-button
        >
      </span>
    </template>
  </el-dialog>
    <!-- 弹框模板 end -->
</template>

<script>
import { eaTaxCategoryList,eaTaxCategorySet, initTaxJt } from '@/api/taxCalculation.js'
export default {
  name:'provision',
  data() {
    return {
      dialogVisible: false, //计提科目设置
      dialogFormVisible: false, //设置凭证模板
      list: [], //科目设置
      temp:{},  //凭证模板
      type:this.$store.getters["user/comInfo"].comKj
    }
  },
  created() {
    
  },
  methods: {
    initTax(){
      initTaxJt({}).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("设置成功")
          this.getList()
        }
      })
    },
    openDialog(){
      this.getList()
      this.dialogVisible = true
    },
    
    handleCreate(row) {
      //console.log(row);
      //console.log(this.temp,'1');
      this.temp = row
      //console.log(this.temp,'2');
      this.dialogFormVisible = true
    },
    updateData() {
      eaTaxCategorySet(this.temp).then(res => {
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          // this.getList()
          this.dialogFormVisible = false
        }
      })
    },
    getList() {
      eaTaxCategoryList().then(res => {
        if(res.data.data.list.length != 0){
          this.list = res.data.data.list
        }else{
          this.list = []
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.iconfont icon-bianji,.iconfont icon-shanchu,.el-icon-setting {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 18px;
    line-height: 24px;
}
.dialog_table{
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-bottom: 0;
  tr{
    line-height: 32px;
    height: 32px;
  }
  td{
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 10px;
    font-size: 14px;
  }
  td:last-child{
    border-right: 0;
  }
}
.bottom_font{
  font-size: 14px;
  color: red;
  line-height: 20px;
  margin-top: 20px;
  
}
</style>