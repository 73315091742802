<template>
  <div class="">
    <el-drawer
      v-model="addDrawer"
      :before-close="handleClose"
      :close-on-click-modal="false"
      size="770px"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            {{ title }}
          </div>
          <div>
            <el-button
              v-if="showType !== DETAILS"
              type="primary"
              @click="saveCule"
              size="small"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="taskForm" :rules="rules" ref="taskForm" label-width="105px" :inline="true">
          <el-form-item label="客户名称:" prop="customerId">
            <!-- <el-input v-model="taskForm.customerName" size="small" class="w500" readonly :disabled="showType === DETAILS">
              <template v-if="showType !== DETAILS" #append>
                <el-icon @click="selectInfo" style="cursor: pointer;"><Search /></el-icon>
              </template>
            </el-input> -->
            <selectCustomer @success="getCustomerInfo" :showType="showType" :types="'customer'" ref="selectCustomer" :list="clueCustomerList"
              v-model:userId="taskForm.customerId" style="width: 585px; margin: 0" />
          </el-form-item>
          <el-form-item label="合同订单号:" prop="contractId">
            <!-- <el-input v-model="taskForm.contractNum" size="small" class="w200" readonly :disabled="showType === DETAILS">
              <template v-if="showType !== DETAILS" #append >
                <el-icon @click="selectNum" style="cursor: pointer;"><Search /></el-icon>
              </template>
            </el-input> -->
            <selectContract @success="getContactInfo" :showType="showType" :types="'contract'" ref="selectCustomer" :list="clueContactList"
              v-model:userId="taskForm.contractId" style="width: 585px; margin: 0" />
          </el-form-item>
          <el-form-item label="业务类型:" prop="businessTypeId">
            <cascader-common
              ref="cascaderCommonRef"
              v-model:modelValue="taskForm.businessTypeId"
              placeholder="请选择业务类型"
              :options="contractTypeOptions"
              :casProps="casProps"
              :disabled="showType === DETAILS"
              @change="changeStep"
              style="width: 220px"
            />
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input v-model="taskForm.remark" size="small" style="width: 227px" :disabled="showType === DETAILS">
            </el-input>
          </el-form-item>
          <div class="tskTable">
            <table class="content" cellspacing="0" width="100%">
              <tr class="top_bg center">
                <td style="width: 15%;height: 40px;text-align: center;">办理步骤</td>
                <td style="width: 35%;height: 40px;text-align: center;">所需资料</td>
                <td style="width: 35%;height: 40px;text-align: center;">备注</td>
                <td style="width: 15%;height: 40px;text-align: center;">操作</td>
              </tr>
              <template v-if="taskForm.newCrmFlowStepList.length > 0">
                <tr
                  class="centers"
                  v-for="(item, index) in taskForm.newCrmFlowStepList"
                  :key="index"
                  
                >
                  <td style="width: 15%">
                    <el-input
                      v-model="item.stepName"
                      size="small"
                      style="width: 100%"
                      placeholder="添加步骤"
                      :disabled="showType === DETAILS"
                    />
                  </td>
                  <td style="width: 35%">
                    <el-input
                      v-model="item.infoNames"
                      size="small"
                      style="width: 100%"
                      placeholder="请选择资料"
                      readonly
                      :disabled="showType === DETAILS"
                    >
                      <template v-if="showType !== DETAILS" #append>
                        <el-icon @click="selectNeededData(index)" style="cursor: pointer;"><Search /></el-icon>
                      </template>
                    </el-input>
                    <!-- <selectInform
                        v-model:modelValue="item.infoNames"
                        placeholder="请选择资料名称"
                        :options="informationList"
                        :disabled="dealDisabled"
                        :casProps="infoProps"
                        style="width: 190px"
                      /> -->
                  </td>
                  <td style="width: 35%">
                    <el-input
                      size="small"
                      style="width: 100%"
                      v-model="item.remark"
                      placeholder="输入内容"
                      :disabled="showType === DETAILS"
                    />
                  </td>
                  <td style="width: 15%;">
                    <div v-if="showType !== DETAILS" style="display: flex;align-items: center;height: 20px;line-height: 20px;justify-content: center;">
                      <el-icon color="#409efc" style="margin-right: 6px;cursor: pointer;" @click="addTaskList(taskForm.newCrmFlowStepList)"><Plus /></el-icon> |
                      <el-icon color="#409efc" style="margin-left: 6px;cursor: pointer;" @click="delTask(taskForm.newCrmFlowStepList, index)"><Minus /></el-icon>
                    </div>
                    <template v-else>
                      <div style="text-align: center;">
                        <el-button type="primary" size="small" v-if="item.status < 3"  @click="() => completeRows(item)">完成</el-button>
                        <span v-if="item.status == 3" style="color: #E67E23">已完成</span>
                        <span v-if="item.status == 4" style="color: #E67E23">已终止</span>
                      </div>
                    </template>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td style="text-align: center" colspan="7">
                  <span style="font-size: 12px;color: #AAAAAA;">暂无相关数据，点击下方按钮快速新增</span>
                  <div
                    style="
                      color: var(--themeColor, #17a2b8);
                      cursor: pointer;
                      z-index: 9999;
                      position: relative;
                    "
                    @click="addTaskList"
                  >
                    +新增
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <el-form-item label="起止日期:" prop="allTime">
            <el-date-picker
              v-model="taskForm.allTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              style="width: 250px;margin: 0;"
              :disabled="showType === DETAILS"
            />
          </el-form-item>
          <el-form-item label="设置办理人:" prop="handleId">
            <selectuser code="addCustomer" v-model:userId="taskForm.handleId" :multiples="false" style="width: 200px;margin: 0;" :disabled="showType === DETAILS"></selectuser>
          </el-form-item>
          <el-form-item label="负责人:" prop="userId">
            <selectuser code="addCustomer" v-model:userId="taskForm.userId" :multiples="false" style="width: 250px;margin: 0;" :disabled="showType === DETAILS"></selectuser>
          </el-form-item>
        </el-form>
      </template>
    </el-drawer>
    <!-- <el-dialog
      v-model="openNum"
      :title="titleDig"
      width="790"
      :close-on-click-modal="false"
      align-center
    >
      <div v-if="type=='order'">
        <el-table
          :data="clueAllList"
          style="width: 100%; margin-top: 10px"
          border
          :height="contentStyleObj"
          @selection-change="handleSelectClue"
          v-loading="listLoading"
          stripe
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="selection" width="55" align="center" fixed />
          <el-table-column
            prop="customerNum"
            label="客户编码"
            min-width="110"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="客户名称"
            min-width="180"
            align="left"
          />
          <el-table-column
            prop="contractNum"
            label="合同（订单）号"
            min-width="110"
            align="left"
          />
          <el-table-column
            prop="businessType"
            label="业务类型"
            min-width="200"
            align="left"
          />
          <el-table-column
            prop="receivedAmount"
            label="金额"
            align="left"
          />
        </el-table>
      </div>
      <div v-else>
        <el-input placeholder="请输入客户编码或客户名称" v-model="listQuery.customerName" style="width: 260px;margin-left:5px" size="small" @keyup.enter="getCustomer" clearable/>
        <el-button
          type="primary"
          @click="getCustomer"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <el-table
          :data="clueAllList"
          style="width: 100%; margin-top: 10px"
          border
          :height="contentStyleObj"
          @selection-change="handleSelectClue"
          v-loading="listLoading"
          stripe
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="selection" width="55" align="center" fixed />
          <el-table-column
            prop="customerNum"
            label="客户编码"
            min-width="110"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="客户名称"
            min-width="180"
            align="left"
          />
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="openNum = false" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="save()">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog> -->
    <el-dialog
      v-model="dialogVisible"
      title="任务办理"
      width="360"
      :close-on-click-modal="false"
      align-center
    >
      <div style="display: flex;font-size: 13px;">
        <span>
          办理情况：
        </span>
        <el-input
          v-model="taskTextarea"
          style="width: 240px"
          :rows="2"
          type="textarea"
          placeholder="请输入办理情况"
        />
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="saveTaskStep">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
    <selectinformation ref="selectinformation" @success="getNeededData"></selectinformation>
  </div>
</template>

<script>
import selectinformation from "../../clue/components/selectInformation.vue"
import { customerContractList,customerList, contractTypeList, taskCreate, taskStepDone,contractInformation,flowList } from "@/api/newCrm.js";
import { DETAILS } from '../utils'
import selectCustomer from "./selectCustomer.vue";
import selectContract from "./selectContract.vue";
import selectInform from "./selectInform.vue";

export default {
  name: '',
  components: {
    selectinformation,
    selectCustomer,
    selectInform,
    selectContract
  },
  props: {
    title:{
      type: String,
      default: '新建任务'
    }
  },
  data () {
    return {
      dialogVisible: false,
      infoProps:{
        expandTrigger: "hover",
        label: "label",
        value: "id",
        multiple: true
      },
      addDrawer:false,
      taskForm:{
        newCrmFlowStepList:[],
        remark: '',
      },
      openNum:false,
      clueCustomerList: [],
      clueContactList: [],
      type:'',
      casProps:{
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      listQuery:{
        customerType: '2',
        customerName:'',
      },
      selects: [],
      titleDig: '',
      listLoading: false,
      innerRow: undefined,
      contractTypeOptions: [],
      showType: '',
      DETAILS,
      taskTextarea:'',
      stepTask:{},
      rules:{
        contractId: [
          { required: true, message: '请选择合同订单号', trigger: 'change' }
        ],
        businessTypeId: [
          { required: true, message: '请填写业务类型', trigger: 'change' }
        ],
        customerId: [
          { required: true, message: '请选择客户名称', trigger: 'change' }
        ],
        handleId: [
          { required: true, message: '请选择办理人', trigger: 'change' }
        ],
        allTime: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.contentStyleObj = this.$getHeight(318);
  },
  mounted () {},
  methods: {
    getInformation(){
      contractInformation().then(res => {
      if(res.data.msg == 'success'){
        this.informationList = res.data.data.data;
      }
    });
    },
    completeRows (item) {
      this.taskTextarea = ''
      this.dialogVisible = true
      this.stepTask = item
    },
    saveTaskStep(){
      if(!this.taskTextarea){
        this.$message.error("请填写办理情况")
        return
      }
      taskStepDone({id: this.stepTask.id,status: 3,taskId:this.innerRow.id,situation:this.taskTextarea}).then((res) => {
        if (res.data.msg == "success") {
          this.$message.success("操作成功")
          this.stepTask.status = '3'
          this.dialogVisible = false
          this.$emit('success')
        }
      });
    },
    saveCule() {
      this.$refs['taskForm'].validate((valid) => {
        if (valid) {
          const nodes = this.$refs.cascaderCommonRef.getCheckedNodes()
          let sfSave = true
          if (nodes.length == 0) {
            this.$message.error("请选择业务类型")
            return
          }
          if (this.taskForm.newCrmFlowStepList.length == 0) {
            this.$message.error("办理步骤不能为空，请填写办理步骤")
            return
          }else{
            this.taskForm.newCrmFlowStepList.forEach(item => {
              if (item.stepName == '') {
                sfSave = false
                this.$message.error("办理信息填写不完整，请补充完整")
                return
              }
              if (item.infoNames == '') {
                sfSave = false
                this.$message.error("办理信息填写不完整，请补充完整")
                return
              }
            })
          }
          if(sfSave){
            taskCreate({
              ...this.taskForm,
              startTime: this.taskForm.allTime[0],
              endTime: this.taskForm.allTime[1],
              businessType: nodes?.[0]?.data.name,
              newCrmFlowStepList: this.taskForm.newCrmFlowStepList.map(item => {
                return {
                  ...item,
                  id: undefined,
                }
              })
            }).then((res) => {
              if (res.data.msg == "success") {
                this.$message.success("保存成功")
                this.addDrawer = false
                this.$emit('success')
              }
            });
          }
        }
      })
    },
    addTaskList() {
      this.taskForm.newCrmFlowStepList.push({
        stepName: "",
        infoNames: "",
        remark: "",
      });
    },
    handleClose() {
      this.taskForm ={
        newCrmFlowStepList:[]
      }
      this.showType = ''
      this.addDrawer = false
    },
    init(row, showType){
      this.getCustomer();
      this.getInformation()
      if (row) {
        this.innerRow = row
        this.taskForm.newCrmFlowStepList = row.stepList || []
        this.taskForm.customerName = row.customerName
        this.taskForm.customerId = row.customerId
        this.taskForm.contractId = row.contractId
        this.taskForm.businessTypeId = row.businessTypeId
        this.taskForm.remark = row.remark
        this.taskForm.allTime = [row.startTime, row.endTime]
        this.taskForm.handleId = row.handleId
        this.taskForm.userId = row.userId
      }
      if (showType) {
        this.showType = showType
        this.getContact()
      }
      contractTypeList({ contractType: '100' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
      this.addDrawer = true;
    },
    selectNeededData(index){
      this.$refs.selectinformation.init('task',index)
    },
    getNeededData(val,index){
      let tempId = []
      let tempName = []
      val.forEach(item =>{
        tempId.push(item.id)
        tempName.push(item.label)
      })
      this.taskForm.newCrmFlowStepList[index].infos = tempId.toString();
      this.taskForm.newCrmFlowStepList[index].infoNames = tempName.toString();
    },
    // 选择客户弹窗
    selectInfo(){
      this.clueAllList = []
      this.openNum = true
      this.type = 'customer'
      this.titleDig = '选择客户'
      this.getCustomer();
    },
    // 获取客户信息
    getCustomer(){
      customerList(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.clueCustomerList = res.data.data.list
        }
      });
    },
     // 获取合同信息
     getContact(){
      customerContractList({customerId:this.taskForm.customerId}).then((res) => {
        if (res.data.msg == "success") {
          this.clueContactList = res.data.data.list
        }
      });
    },
    // 选中客户，获取客户详细信息
    getCustomerInfo(val){
      let tempList = this.clueCustomerList.filter(item =>{
        return item.id == val
      })
      if(tempList.length > 0){
        this.taskForm.customerName = tempList[0].customerName
        this.taskForm.contractId = undefined
        this.getContact()
      }
    },
    // 选中合同，获取合同详细信息
    getContactInfo(val){
      let tempList = this.clueContactList.filter(item =>{
        return item.id == val
      })
      this.taskForm.customerName = tempList[0].customerName
      this.taskForm.contractNum = tempList[0].contractNum
      this.taskForm.businessTypeId = tempList[0].businessTypeId
      let contractType = tempList[0].contractType
      if(contractType == 0){
        contractTypeList({ contractType: '1' }).then((res) => {
          this.contractTypeOptions = res.data.data.list;
        });
      }else{
        contractTypeList({ contractType: '2' }).then((res) => {
          this.contractTypeOptions = res.data.data.list;
        });
      }
      this.getStep(this.taskForm.businessTypeId)
    },
    // 获取步骤
    getStep(val){
      flowList({businessTypeId:val}).then((res) => {
        if (res.data.msg == "success" && res.data.data.data.length > 0) {
          this.taskForm.newCrmFlowStepList = res.data.data.data[0].stepList
        }
      })
    },
    // 业务类型变化
    changeStep(){
      this.taskForm.newCrmFlowStepList = []
      if(this.taskForm.businessTypeId){
        this.getStep(this.taskForm.businessTypeId)
      }
    },
    // 选择合同弹窗
    selectNum(){
      this.clueAllList = []
      this.openNum = true
      this.type = 'order'
      this.titleDig = '选择合同（订单）号'
      this.getContact()
    },


    // 新增任务
    addTask(item){
      item.push({
        customerName:'',
      });
    },

    // 删除任务
    delTask(item,index){
      item.splice(index, 1);
    },

    // 列表勾选
    handleSelectClue(val) {
      this.selects = val;
    },

    save(){
      if(this.type == 'order'){
        this.taskForm.businessTypeId = this.selects[0].businessTypeId
        this.taskForm.customerNum = this.selects[0].customerNum
        this.taskForm.contractId = this.selects[0].id //
        this.taskForm.contractNum = this.selects[0].contractNum //合同编号
        // this.taskForm.newCrmFlowStepList.push({
          
        // })
      }else{
        this.taskForm.customerName = this.selects[0].customerName
        this.taskForm.customerId = this.selects[0].id
      }
      this.openNum = false
    }
  }
}
</script>

<style scoped lang="less">
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.w200{
  width: 250px;
  margin: 0;
}
.w100{
  width: 100px;
  margin: 0;
}
.w500{
  width: 585px;
  margin: 0;
}
.tskTable{
  width: 95%;
  margin: 0 auto;
  height: 280px;
  background-color: #F3F4F5;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
}
.circle{
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDCDCD;
  border-radius: 50%;
}
.taskList{
  display: flex;
  height: 40px;
  line-height: 40px;
  justify-content: space-around;
  align-items: center;
}
.content {
  width: 92%;
  overflow-x: auto; /* 启用水平滚动条 */  
  white-space: nowrap; /* 防止内容换行 */ 
  min-height: 72px;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span {
    line-height: 28px;
    font-size: 13px;
  }
  tr {
    padding: 0;
    margin: 0;
  }
  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    height: 32px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}
</style>
